import React, { Component } from 'react';
import { FacebookBox, LinkedinBox, TwitterBox } from 'mdi-material-ui';
import kph from './kph.png';
import kpm from './kpm.png';
import './App.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dev: false,
      leftHover: false,
      rightHover: false,
      backHover: false,
      fbHover: false,
      liHover: false,
      twHover: false,
    };
    this.subLeft = React.createRef();
    this.subRight = React.createRef();
    this.subBlock = React.createRef();
    this.title = React.createRef();
    this.body = React.createRef();
  }

  componentDidMount() {
    // When the component is mounted, add your DOM listener to the "nv" elem.
    // (The "nv" elem is assigned in the render function.)
    this.subLeft.current.addEventListener("transitionend", this.handleTransition);
    this.subBlock.current.addEventListener("transitionend", this.handleTransition);
    this.title.current.addEventListener("transitionend", this.handleTransition);
    this.body.current.addEventListener("transitionend", this.handleTransition);
  }

  componentWillUnmount() {
    // Make sure to remove the DOM listener when the component is unmounted.
    this.subLeft.current.removeEventListener("transitionend", this.handleTransition);
    this.subBlock.current.removeEventListener("transitionend", this.handleTransition);
    this.title.current.removeEventListener("transitionend", this.handleTransition);
    this.body.current.removeEventListener("transitionend", this.handleTransition);
  }

  // Use a class arrow function (ES7) for the handler. In ES6 you could bind()
  // a handler in the constructor.
  handleTransition = (event) => {
      if (event.target.className==="sub-left") {
        this.subLeft.current.style.transition = 'none';
      }
      if (event.target.className==="sub-block") {
        this.subBlock.current.style.transition = 'none';
      }
      if (event.target.className==="Title-header") {
        this.title.current.style.transition = 'none';
      }
      if (event.target.className==="Body-base") {
        this.body.current.style.transition = 'none';
        this.body.current.style.overflow = 'auto';
      }
  }

  clickDev = () => {
    //event.preventDefault();
    if (!this.state.dev) {
      this.subLeft.current.style.transition = 'width 2s, left 2s';
      this.subBlock.current.style.transition = 'top 2s';
      this.title.current.style.transition = '2s';
      this.body.current.style.transition = '2s';
      this.body.current.style.overflow = 'hidden';
      this.setState({dev: true});
    }
  }

  clickMusic = () => {
    if (!this.state.dev) window.location.href="https://kenpiersonmusic.com";
  }

  clickBack = () => {
    //event.preventDefault();
    if (this.state.dev) {
      this.subLeft.current.style.transition = 'width 2s, left 2s';
      this.subBlock.current.style.transition = 'top 2s';
      this.title.current.style.transition = '2s';
      this.body.current.style.transition = '2s';
      this.body.current.style.overflow = 'hidden';
      this.setState({dev: false});
    }
  }

  render() {
    return (
      <div className="App">
        <header className="Title-header"
          ref={this.title}
          style={{
            minHeight: this.state.dev ? '' : '100vh',
            }}  
        >
        <div className="Social-container"
          style={{
            opacity: this.state.dev ? '0' : '1',
            transitionDelay: this.state.dev ? '0s' : '',
          }}
        >
        <div className="Social-fade">
          <FacebookBox
            onMouseOver={() => this.setState({fbHover: true})}
            onMouseOut={() => this.setState({fbHover: false})}
            onClick={() => {
              if (!this.state.dev) window.location.href="https://www.facebook.com/kspierson";
            }}
            style={{
              transform: this.state.fbHover && !this.state.dev ? 'translateY(-2px)' : 'translateY(2px)',
              cursor: this.state.fbHover && !this.state.dev ? 'pointer' : 'default',
              transition: "transform 0.5s"
            }}
          />
          <LinkedinBox
            onMouseOver={() => this.setState({liHover: true})}
            onMouseOut={() => this.setState({liHover: false})}
            onClick={() => {
              if (!this.state.dev) window.location.href="https://www.linkedin.com/in/kspierson/";
            }}
            style={{
              transform: this.state.liHover && !this.state.dev ? 'translateY(-2px)' : 'translateY(2px)',
              cursor: this.state.liHover && !this.state.dev ? 'pointer' : 'default',
              transition: "transform 0.5s"
            }}
          />
          <TwitterBox
            onMouseOver={() => this.setState({twHover: true})}
            onMouseOut={() => this.setState({twHover: false})}
            onClick={() => {
              if (!this.state.dev) window.location.href="https://twitter.com/ken_pierson";
            }}
            style={{
              transform: this.state.twHover && !this.state.dev ? 'translateY(-2px)' : 'translateY(2px)',
              cursor: this.state.twHover && !this.state.dev ? 'pointer' : 'default',
              transition: "transform 0.5s"
            }}
          />
        </div>
        </div>
        <div className="Back-arrow-box">
        <div className="Back-arrow"
          onClick={this.clickBack}
          onMouseOver={() => this.setState({backHover: true})}
          onMouseOut={() => this.setState({backHover: false})}
          style={{
            opacity: this.state.dev ? '1' : '0',
            transform: this.state.backHover && this.state.dev ? 'translate(1px,-1px)' : 'translate(-1px,1px)',
            cursor: this.state.backHover && this.state.dev ? 'pointer' : 'default'
          }}
        ></div></div>
          <div className="Title-container" 
          style={{
            top: this.state.dev ? '10px' : '50%',
            transform: this.state.dev ? 'scale(0.7)' : 'scale(1)',
            //transition: this.state.dev ? '2s' : '2s',
            }}>
          <div className="Title-box">
          <div className="Square-clip"/>
            <div className="Square-logo"/>
            <div className="block">
              <h1 className="name">KEN PIERSON</h1>
              <div className="sub-block"
                ref={this.subBlock}
                style={{
                  top: this.state.dev ? '' : '-5px',
                }}
              >
              <h2 className="sub-left"
                ref={this.subLeft}
                style={{
                    width: this.state.dev ? '100%' : '200px',
                    left: this.state.dev ? '0px' : '',
                  }}
                >
                <div className="link-left" 
                  onClick={this.clickDev}
                  onMouseOver={() => this.setState({leftHover: true})}
                  onMouseOut={() => this.setState({leftHover: false})}
                  style={{
                    transform: this.state.leftHover && !this.state.dev ? 'translateY(-2px)' : 'translateY(2px)',
                    cursor: this.state.leftHover && !this.state.dev ? 'pointer' : 'default'
                  }}
                >Developer</div>
              </h2>
              <h2 className="sub-right"
                ref={this.subRight}
                style={{
                  opacity: this.state.dev ? '0' : '1',
                  transition: this.state.dev ? 'opacity 0.5s' : 'opacity 1.5s',
                  transitionDelay: this.state.dev ? '0s' : '0.5s',
                }}
              >
                <div className="link-right" 
                  onClick={this.clickMusic}
                  onMouseOver={() => this.setState({rightHover: true})}
                  onMouseOut={() => this.setState({rightHover: false})}
                  style={{
                    transform: this.state.rightHover && !this.state.dev ? 'translateY(-2px)' : 'translateY(2px)',
                    cursor: this.state.rightHover && !this.state.dev ? 'pointer' : 'default'
                  }}
                >Composer</div>
              </h2>
            </div>
            </div>
            <div className="Square-sub" style={{
                opacity: this.state.dev ? '0' : '1',
                transition: this.state.dev ? 'opacity 0.5s' : 'opacity 1s',
                transitionDelay: this.state.dev ? '0s' : '1s',
              }}/>
            <div className="Square-hid"
              style={{
                opacity: this.state.dev ? '0' : '1',
              }}  
            />
            </div>
          </div>
        </header>
        <div className="Body-base"
          ref={this.body}
          style={{
            maxHeight: this.state.dev ? '' : '0px',
            minHeight: this.state.dev ? '' : '0px',
            padding: this.state.dev ? '' : '0px',
          }}
        >
        <div className="Body-content">
          <div className="Body-about"
            style={{
              opacity: this.state.dev ? '1' : '0',
              transitionDelay: this.state.dev ? '1s' : '0s',
            }}
          >
          Experienced with web development using HTML, CSS, JavaScript, jQuery, Bootstrap, PHP, MySQL, and the CodeIgniter framework, and currently use the React framework along with a server-side Web API for building modern and responsive web applications. Solid experience with mobile development using React Native, as well as programming in Java and application development using the Spring Boot framework.
          </div>
          <div className="Body-profile">
            <div className="Body-kph"
              style={{
                opacity: this.state.dev ? '1' : '0',
                transform: this.state.dev ? 'none' : '',
                transitionDelay: this.state.dev ? '2s' : '0s',
              }}
            >
              <img src={kph} />
              <div className="Kph-desc">
                <h3 className="Site-title">kenpierson.com</h3>
                <h4 className="Fe-title">Front-end:</h4>
                <ul style={{margin: 0}}>
                  <li>React (JavaScript/JSX)</li>
                  <li>Pure CSS Animations</li>
                </ul>
                <h4 className="Be-title">Back-end:</h4>
                <ul style={{margin: 0}}>
                  <li>RESTful Web API (using CodeIgniter)</li>
                  <li>User Authentication</li>
                </ul>
              </div>
            </div>
            <div className="Body-kpm"
              style={{
                opacity: this.state.dev ? '1' : '0',
                transform: this.state.dev ? 'none' : '',
                transitionDelay: this.state.dev ? '3s' : '0s',
              }}
            >
              <a href="https://kenpiersonmusic.com"><img src={kpm} /></a>
              <div className="Kpm-desc">
                <h3 className="Site-title"><a href="https://kenpiersonmusic.com">kenpiersonmusic.com</a></h3>
                <h4 className="Fe-title">Front-end:</h4>
                <ul style={{margin: 0}}>
                  <li>Bootstrap (HTML/CSS)</li>
                  <li>jQuery (JavaScript)</li>
                </ul>
                <h4 className="Be-title">Back-end:</h4>
                <ul style={{margin: 0}}>
                  <li>CodeIgniter (PHP/MySQL)</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    );
  }
}

export default App;